





















































































import { ContainerMixin } from '@/common/mixin/containerMixin';
import { Select, Option } from 'element-ui';
import { Term } from '../../../common/Term';
import VueRouter from 'vue-router';
import {
  TransactionActionState,
  TransactionActionStateLabels,
} from '../model/TransactionActionState';
import { TransactionFilterQuery, toFilter } from '../model/TransactionFilter';
import { toStringArray } from '@/common/util/toStringArray';

import { apolloClient } from '@/apolloClient';
import { OrderService } from '../service/OrderService';

const service = new OrderService(apolloClient);

export default ContainerMixin.extend({
  name: 'TransactionFilterForm',
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    const query = this.$route.query as TransactionFilterQuery;
    let searchTarget = 'orderId';
    let searchKeyword = '';

    if (query.productTitleLike) {
      searchTarget = 'productTitle';
      searchKeyword = query.productTitleLike;
    } else if (query.productIds) {
      searchTarget = 'productId';
      searchKeyword = toStringArray(query.productIds)?.join(',') || '';
    } else if (query.itemNameLike) {
      searchTarget = 'itemName';
      searchKeyword = query.itemNameLike;
    } else if (query.orderIds) {
      searchTarget = 'orderId';
      searchKeyword = toStringArray(query.orderIds)?.join(',') || '';
    } else if (query.ids) {
      searchTarget = 'tid';
      searchKeyword = toStringArray(query.ids)?.join(',') || '';
    }

    return {
      searchTarget: searchTarget,
      searchKeyword: searchKeyword,
      totalCount: 0,
      orderSearchOptions: [
        { value: 'orderId', text: '주문ID' },
        { value: 'productTitle', text: '상품명' },
        { value: 'productId', text: '상품ID' },
        { value: 'itemName', text: '아이템명' },
        { value: 'tid', text: '거래 ID' },
      ],
      customerId: toStringArray(query.customerIds)?.join(',') || '',
      nickname: query.nameLike || '',
      mobileNumber: query.phoneLike || '',
      visible: false,
      transactionTerm: {
        startedAt: query.startedAt ? Number(query.startedAt) : null,
        endedAt: query.endedAt ? Number(query.endedAt) : null,
      } as Term,
      actionStatusIn:
        query.actionStatusIn?.map(i => i as TransactionActionState) ||
        TransactionActionStateLabels.map(i => i.value),
      actionStateLabels: TransactionActionStateLabels,
    };
  },
  computed: {
    searchKeywordPlaceholder(): string {
      if (this.searchTarget === 'productId') {
        return '상품 아이디를 , 로 구분해 입력해주세요.';
      } else if (this.searchTarget === 'orderId') {
        return '주문 아이디를 , 로 구분해 입력해주세요.';
      } else if (this.searchTarget === 'tid') {
        return '거래 아이디를 , 로 구분해 입력해주세요.';
      } else {
        return '검색어를 입력해주세요';
      }
    },
  },
  methods: {
    clear() {
      this.searchTarget = 'productTitle';
      this.searchKeyword = '';
      this.customerId = '';
      this.nickname = '';
      this.mobileNumber = '';
      this.transactionTerm = {
        startedAt: null,
        endedAt: null,
      };
      this.actionStatusIn = TransactionActionStateLabels.map(i => i.value);
    },
    onClickSearch() {
      const filter = this.buildFilter();
      this.$router.push({ query: filter }).catch(err => {
        if (
          VueRouter.isNavigationFailure(
            err,
            VueRouter.NavigationFailureType.duplicated
          )
        ) {
          this.$emit('refresh', true);
        }
      });
    },
    buildFilter() {
      const filter: TransactionFilterQuery = {};

      if (this.searchKeyword) {
        if (this.searchTarget === 'productTitle') {
          filter.productTitleLike = this.searchKeyword;
        } else if (this.searchTarget === 'productId') {
          filter.productIds = this.searchKeyword
            .split(',')
            .map(id => id.trim());
        } else if (this.searchTarget === 'itemName') {
          filter.itemNameLike = this.searchKeyword;
        } else if (this.searchTarget === 'orderId') {
          filter.orderIds = this.searchKeyword.split(',').map(id => id.trim());
        } else if (this.searchTarget === 'tid') {
          filter.ids = this.searchKeyword.split(',').map(id => id.trim());
        }
      }

      if (this.customerId) {
        filter.customerIds = this.customerId.split(',').map(i => i.trim());
      }
      if (this.nickname) {
        filter.nameLike = this.nickname;
      }
      if (this.mobileNumber) {
        filter.phoneLike = this.mobileNumber.replace(/-/g, '');
      }
      if (this.transactionTerm.startedAt) {
        filter.startedAt = this.transactionTerm.startedAt.toString();
      }
      if (this.transactionTerm.endedAt) {
        filter.endedAt = this.transactionTerm.endedAt.toString();
      }

      filter.actionStatusIn = this.actionStatusIn;

      return filter;
    },
    async downloadFile(): Promise<void> {
      const filter = this.buildFilter();
      try {
        const result = await service.generateFile(toFilter(filter));
        if (!result.success) {
          return this.$modal.show({
            title: '엑셀 다운로드 실패',
            message: result.message,
            type: 'warning',
          });
        }

        window.location.href = result.url;
      } catch (err) {
        this.showErrorAlert(err, '엑셀 다운로드 실패');
      }
    },
  },
});
