
















































































import Vue from 'vue';
import { Select, Option } from 'element-ui';
import { TransactionStateLabels } from '@/domain/purchase/model/enums';
import { OrderFilterQuery } from '../model/OrderFilter';
import { Term } from '../../../common/Term';
import VueRouter from 'vue-router';

export default Vue.extend({
  name: 'OrderSearchFilterForm',
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      searchTarget: 'productTitle',
      searchKeyword: '',
      totalCount: 0,
      orderSearchOptions: [
        { value: 'productTitle', text: '상품명' },
        { value: 'productId', text: '상품ID' },
        { value: 'itemName', text: '아이템명' },
        { value: 'orderId', text: '주문ID' },
      ],
      customerId: '',
      nickname: '',
      mobileNumber: '',
      visible: false,
      transactionTerm: {
        startedAt: null,
        endedAt: null,
      } as Term,
      transactionStatusIn: TransactionStateLabels.map(i => i.value),
      transactionStateLabels: TransactionStateLabels,
    };
  },
  computed: {
    searchKeywordPlaceholder(): string {
      if (this.searchTarget === 'productId') {
        return '상품 아이디를 , 로 구분해 입력해주세요.';
      } else if (this.searchTarget === 'orderId') {
        return '주문 아이디를 , 로 구분해 입력해주세요.';
      } else {
        return '검색어를 입력해주세요';
      }
    },
  },
  methods: {
    clear() {
      this.searchTarget = 'productTitle';
      this.searchKeyword = '';
      this.customerId = '';
      this.nickname = '';
      this.mobileNumber = '';
      this.transactionTerm = {
        startedAt: null,
        endedAt: null,
      };
      this.transactionStatusIn = TransactionStateLabels.map(i => i.value);
    },
    buildFilter() {
      const filter: OrderFilterQuery = {};

      if (this.searchKeyword) {
        if (this.searchTarget === 'productTitle') {
          filter.productTitleLike = this.searchKeyword;
        } else if (this.searchTarget === 'productId') {
          filter.productIds = this.searchKeyword
            .split(',')
            .map(id => id.trim());
        } else if (this.searchTarget === 'itemName') {
          filter.itemNameLike = this.searchKeyword;
        } else if (this.searchTarget === 'orderId') {
          filter.orderIds = this.searchKeyword.split(',').map(id => id.trim());
        }
      }

      if (this.customerId) {
        filter.customerIds = this.customerId.split(',').map(i => i.trim());
      }
      if (this.nickname) {
        filter.nameLike = this.nickname;
      }
      if (this.mobileNumber) {
        filter.phoneLike = this.mobileNumber.replace(/-/g, '');
      }
      if (this.transactionTerm.startedAt) {
        filter.startedAt = this.transactionTerm.startedAt.toString();
      }
      if (this.transactionTerm.endedAt) {
        filter.endedAt = this.transactionTerm.endedAt.toString();
      }

      filter.transactionStatusIn = this.transactionStatusIn;

      this.$router.push({ query: filter }).catch(err => {
        if (
          VueRouter.isNavigationFailure(
            err,
            VueRouter.NavigationFailureType.duplicated
          )
        ) {
          this.$emit('refresh', true);
        }
      });
    },
  },
});
