




















































import Vue from 'vue';
import { Table, TableColumn } from 'element-ui';
import TransactionLabel from '@/domain/purchase/components/controls/TransactionLabel.vue';
import MoneyLabel from '@/components/Labels/MoneyLabel.vue';
import { OrderPayment } from '../model/OrderPayment';
import { Transaction } from '../model/Transaction';
import CouponPaymentLink from './controls/CouponPaymentLink.vue';

interface OrderPaymentRow {
  payment: OrderPayment;
  transaction: Transaction;
  firstRow: boolean;
}

export default Vue.extend({
  name: 'OrderPaymentInfo',
  components: {
    TransactionLabel,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    MoneyLabel,
    CouponPaymentLink,
  },
  props: {
    value: {
      type: Object,
    },
  },
  computed: {
    transactions: function (): Transaction[] {
      return this.value.transactions || [];
    },
    rows: function (): OrderPaymentRow[] {
      return this.transactions.flatMap(t =>
        t.payments.map((i, index) => {
          return {
            payment: i,
            transaction: t,
            firstRow: index == 0,
          };
        })
      );
    },
  },
  methods: {
    copyPid: function (pid: string) {
      const storage = document.createElement('textarea');
      storage.value = pid;
      // storage.value = this.$refs.copyRef.innerHTML;
      if (this.$refs.copyRef) {
        (this.$refs.copyRef as HTMLDivElement).appendChild(storage);
      }
      storage.select();
      storage.setSelectionRange(0, 99999);
      document.execCommand('copy');
      if (this.$refs.copyRef) {
        (this.$refs.copyRef as HTMLDivElement).removeChild(storage);
      }
    },
    getTableSpan({
      row,
      column,
      rowIndex,
      columnIndex,
    }: {
      row: OrderPaymentRow;
      column: any;
      rowIndex: number;
      columnIndex: number;
    }): { rowspan: number; colspan: number } {
      if (columnIndex < 0) {
        if (row.firstRow) {
          return {
            rowspan: row.transaction.payments.length,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
      return {
        rowspan: 1,
        colspan: 1,
      };
    },
  },
});
