var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('content-card',{attrs:{"title":"거래 내역"}},[_c('el-table',{ref:"table",staticClass:"table table-responsive table-flush table-striped align-items-center",attrs:{"empty-text":"검색 결과가 없습니다.","header-row-class-name":"thead-light","header-cell-class-name":"center-table","cell-class-name":"text-center","data":_vm.transactions}},[_c('el-table-column',{attrs:{"type":"index","width":"80","label":"순번"}}),_c('el-table-column',{attrs:{"label":"거래유형"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('transaction-label',{attrs:{"transaction":row}})]}}])}),_c('el-table-column',{attrs:{"label":"유저ID"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.worker ? row.worker.id : '-')+" ")]}}])}),_c('el-table-column',{attrs:{"label":"취소사유 유형"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.cancelReasonKind)+" ")]}}])}),_c('el-table-column',{attrs:{"label":"거래일"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('instant',{attrs:{"at":row.firmedAt}})]}}])}),_c('el-table-column',{attrs:{"label":"취소사유"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.cancelReason)+" ")]}}])}),_c('el-table-column',{attrs:{"width":"130","label":"액션"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.canCancel(row))?_c('button',{staticClass:"btn btn-sm btn-danger",attrs:{"type":"button"},on:{"click":function($event){return _vm.onClickProcessCancel(row)}}},[_vm._v(" 취소 처리 ")]):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }