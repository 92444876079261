export enum OrderState {
  STAND_BY = 'STAND_BY',
  COMPLETED = 'COMPLETED',
  CANCEL_REQUESTED = 'CANCEL_REQUESTED',
  CANCELING = 'CANCELING',
  CANCELED = 'CANCELED',
}

export const OrderStateLabels: { value: OrderState; label: string }[] = [
  {
    value: OrderState.STAND_BY,
    label: '입금 대기',
  },
  {
    value: OrderState.COMPLETED,
    label: '주문 완료',
  },
  {
    value: OrderState.CANCEL_REQUESTED,
    label: '취소 요청',
  },
  {
    value: OrderState.CANCELING,
    label: '취소 중',
  },
  {
    value: OrderState.CANCELED,
    label: '취소 완료',
  },
];
