export enum TransactionActionState {
  PURCHASE_WAITING = 'PURCHASE_WAITING',
  PURCHASE_COMPLETED = 'PURCHASE_COMPLETED',

  CANCELLATION_REQUESTED = 'CANCELLATION_REQUESTED',
  CANCELLATION_COMPLETED = 'CANCELLATION_COMPLETED',
  CANCELLATION_RETRACTED = 'CANCELLATION_RETRACTED',
}

export const TransactionActionStateLabels: {
  value: TransactionActionState;
  label: string;
  variant: string;
}[] = [
  // {
  //   value: TransactionActionState.PURCHASE_WAITING,
  //   label: '입금 대기',
  //   variant: 'success',
  // },
  {
    value: TransactionActionState.PURCHASE_COMPLETED,
    label: '결제 완료',
    variant: 'primary',
  },
  {
    value: TransactionActionState.CANCELLATION_REQUESTED,
    label: '취소 신청',
    variant: 'warning',
  },
  {
    value: TransactionActionState.CANCELLATION_COMPLETED,
    label: '취소 완료',
    variant: 'danger',
  },
  {
    value: TransactionActionState.CANCELLATION_RETRACTED,
    label: '취소 철회',
    variant: 'info',
  },
];
