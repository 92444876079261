
















































































import Vue from 'vue';
import { Table, TableColumn } from 'element-ui';
import Spinner from '@/components/Spinner.vue';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import { ORDER_SEARCH_QUERY } from '../graphql/query';
import OrderStatusLabel from './controls/OrderStatusLabel.vue';
import { OrderSheet } from '../model/OrderSheet';
import { OrderFilter, OrderFilterQuery } from '../model/OrderFilter';
import { Term } from '@/common/Term';
import { OrderState } from '../model/OrderState';
import { TransactionState, TransactionType } from '../../purchase/model/enums';

interface Connection<T> {
  totalCount: number;
  edges: Edge<T>[];
}

interface Edge<T> {
  node: T;
}

export default Vue.extend({
  name: 'OrderSearchList',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Spinner,
    OrderStatusLabel,
  },
  data() {
    return {
      loading: 0,
      orders: [],
      totalCount: 0,
      size: 20,
    };
  },
  computed: {
    numberOfPages(): number {
      return Math.ceil(this.totalCount / this.size);
    },
    filter(): OrderFilter {
      const query = this.$route.query as OrderFilterQuery;

      var term: Term | null = null;
      if (query.startedAt || query.endedAt) {
        term = {
          startedAt: query.startedAt ? Number(query.startedAt) : null,
          endedAt: query.endedAt ? Number(query.endedAt) : null,
        };
      }

      return {
        productIds: this.toArray(query.productIds)?.map(i => Number(i)) || null,
        customerIds:
          this.toArray(query.customerIds)?.map(i => Number(i)) || null,
        nameLike: query.nameLike || null,
        emailLike: query.emailLike || null,
        phoneLike: query.phoneLike || null,
        itemIds: this.toArray(query.itemIds)?.map(i => Number(i)) || null,
        transactionTerm: term,
        statusIn:
          this.toArray(query.statusIn)?.map(i => i as OrderState) || null,
        transactionStatusIn:
          this.toArray(query.transactionStatusIn)?.map(
            i => i as TransactionState
          ) || null,
        productTitleLike: query.productTitleLike || null,
        itemNameLike: query.itemNameLike || null,
        orderIds: this.toArray(query.orderIds) || null,
      };
    },
  },
  methods: {
    toArray(items?: string | string[]): string[] | undefined {
      if (items) {
        if (Array.isArray(items)) {
          return items as string[];
        }
        return [items];
      }
    },
    linkGen(pageNum: number) {
      return {
        query: {
          ...this.$route.query,
          page: pageNum > 1 ? pageNum : undefined,
        },
      };
    },
    onClickDetail(order: OrderSheet) {
      console.log(order);
      this.$router.push(`view/${order.id}`);
    },
    getGenderName(order: OrderSheet) {
      let text = '-';
      switch (order.customer.gender) {
        case 'M':
          text = '남성';
          break;
        case 'F':
          text = '여성';
          break;
      }
      return text;
    },
    getProductInfo(order: OrderSheet) {
      let title = order.items[0]?.info.product.title;
      title += '<br />' + order.items[0].info.name;

      if (order.items.length > 1) {
        title += ` 외 ${order.items.length - 1}건`;
      }

      return title;
    },
    getPurchaseCount(order: OrderSheet) {
      return order.items.reduce((sum, item) => sum + item.count, 0);
    },
    getCancelCount(order: OrderSheet) {
      return order.items.reduce(
        (sum, item) => sum + (item.count - item.remains),
        0
      );
    },
    getCancelRequestedCount(order: OrderSheet): number {
      return order.transactions.filter(
        i =>
          i.type == TransactionType.CANCELLATION &&
          i.status == TransactionState.REQUESTED
      ).length;
    },
    getCancelCompletedCount(order: OrderSheet): number {
      return order.transactions.filter(
        i =>
          i.type == TransactionType.CANCELLATION &&
          i.status == TransactionState.COMPLETED
      ).length;
    },
    getCancelFailedCount(order: OrderSheet): number {
      return order.transactions.filter(
        i =>
          i.type == TransactionType.CANCELLATION &&
          i.status == TransactionState.FAILED
      ).length;
    },
    refresh() {
      this.$apollo.queries.orders.refresh();
    },
  },
  apollo: {
    orders: {
      query: ORDER_SEARCH_QUERY,
      variables(): { page: number; size: number; filter: OrderFilter } {
        return {
          page: Number(this.$route.query.page) || 1,
          size: this.size,
          filter: this.filter,
        };
      },
      error(e: ApolloError) {
        console.error(e);
      },
      // manual: true,
      result(
        result: ApolloQueryResult<{
          order: { orders: Connection<OrderSheet> };
        }>
      ) {
        this.totalCount = result.data.order.orders.totalCount;
      },
      update: (data: { order: { orders: Connection<OrderSheet> } }) =>
        data.order.orders.edges.map(edge => edge.node),
    },
  },
});
