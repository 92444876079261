






























































import Vue from 'vue';
import { Table, TableColumn } from 'element-ui';
import Spinner from '@/components/Spinner.vue';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import { TRANSACTION_LIST_QUERY } from '../graphql/query';
import TransactionActionStatusLabel from './controls/TransactionActionStatusLabel.vue';
import MoneyLabel from '@/components/Labels/MoneyLabel.vue';
import {
  TransactionFilter,
  TransactionFilterQuery,
} from '../model/TransactionFilter';
import { Term } from '@/common/Term';
import { TransactionListItem } from '../model/Transaction';
import { TransactionActionState } from '../model/TransactionActionState';
import Instant from '@/components/Labels/Instant.vue';
import { toStringArray } from '@/common/util/toStringArray';

interface Connection<T> {
  totalCount: number;
  edges: Edge<T>[];
}

interface Edge<T> {
  node: T;
}

export default Vue.extend({
  name: 'TransactionListList',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    Spinner,
    TransactionActionStatusLabel,
    MoneyLabel,
    Instant,
  },
  data() {
    return {
      loading: 0,
      transactions: [],
      totalCount: 0,
      size: 20,
    };
  },
  computed: {
    numberOfPages(): number {
      return Math.ceil(this.totalCount / this.size);
    },
    filter(): TransactionFilter {
      const query = this.$route.query as TransactionFilterQuery;

      var term: Term | null = null;
      if (query.startedAt || query.endedAt) {
        term = {
          startedAt: query.startedAt ? Number(query.startedAt) : null,
          endedAt: query.endedAt ? Number(query.endedAt) : null,
        };
      }

      return {
        ids: toStringArray(query.ids) || null,
        productIds: toStringArray(query.productIds) || null,
        customerIds:
          toStringArray(query.customerIds)?.map(i => Number(i)) || null,
        nameLike: query.nameLike || null,
        emailLike: query.emailLike || null,
        phoneLike: query.phoneLike || null,
        itemIds: toStringArray(query.itemIds)?.map(i => Number(i)) || null,
        transactionTerm: term,
        actionStatusIn:
          toStringArray(query.actionStatusIn)?.map(
            i => i as TransactionActionState
          ) || null,
        productTitleLike: query.productTitleLike || null,
        itemNameLike: query.itemNameLike || null,
        orderIds: toStringArray(query.orderIds) || null,
        orderItemIds: toStringArray(query.orderItemIds) || null,
      };
    },
  },
  methods: {
    linkGen(pageNum: number) {
      return {
        query: {
          ...this.$route.query,
          page: pageNum > 1 ? pageNum : undefined,
        },
      };
    },
    onClickDetail(row: TransactionListItem) {
      console.log(row);
      this.$router.push(`view/${row.order.id}`);
    },
    getGenderName(row: TransactionListItem) {
      let text = '-';
      switch (row.order.customer.gender) {
        case 'M':
          text = '남성';
          break;
        case 'F':
          text = '여성';
          break;
      }
      return text;
    },
    getProductInfo(row: TransactionListItem) {
      let title = row.items[0]?.info.product.title;
      title += '<br />' + row.items[0].info.name;

      if (row.items.length > 1) {
        title += ` 외 ${row.items.length - 1}건`;
      }

      return title;
    },
    getCount(row: TransactionListItem) {
      return row.items.reduce((sum, item) => sum + item.count, 0);
    },
    getPaymentInfo(row: TransactionListItem) {
      return row.payments
        .map(
          i => `${i.methodLabel}: ${Number(i.amount.value).toLocaleString()}`
        )
        .join(', ');
    },
    refresh() {
      this.$apollo.queries.transactions.refresh();
    },
  },
  apollo: {
    transactions: {
      query: TRANSACTION_LIST_QUERY,
      variables(): { page: number; size: number; filter: TransactionFilter } {
        return {
          page: Number(this.$route.query.page) || 1,
          size: this.size,
          filter: this.filter,
        };
      },
      error(e: ApolloError) {
        console.error(e);
      },
      // manual: true,
      result(
        result: ApolloQueryResult<{
          order: { transactions: Connection<TransactionListItem> };
        }>
      ) {
        this.totalCount = result.data.order.transactions.totalCount;
      },
      update: (data: {
        order: { transactions: Connection<TransactionListItem> };
      }) => data.order.transactions.edges.map(edge => edge.node),
    },
  },
});
