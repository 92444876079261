
















import Vue from 'vue';
import { COUPON_PAYMENT_QUERY } from '../../graphql/query';

interface CouponPament {
  id: string;
  issuedCoupon: {
    id: string;
    coupon: {
      id: string;
      title: string;
      description: string;
    };
  };
}

export default Vue.extend({
  name: 'CouponPaymentLink',
  props: {
    pid: {
      type: String,
    },
  },
  data() {
    return {
      couponPayment: {} as CouponPament,
    };
  },
  computed: {
    couponId(): string {
      return this.couponPayment?.issuedCoupon?.coupon?.id || '';
    },
    couponTitle(): string {
      return this.couponPayment?.issuedCoupon?.coupon?.title || '';
    },
  },
  apollo: {
    couponPayment: {
      query: COUPON_PAYMENT_QUERY,
      variables(): { pid: string } {
        return {
          pid: this.pid,
        };
      },
      update: (data: { payment: { coupon: CouponPament } }) =>
        data.payment.coupon,
    },
  },
});
