





























import Vue from 'vue';
import { OrderSheetDetail } from '../model/OrderSheetDetail';

export default Vue.extend({
  name: 'OrderInfo',
  props: {
    value: {
      type: Object,
    },
  },
  computed: {
    order: function (): OrderSheetDetail {
      return this.value as OrderSheetDetail;
    },
    orderId: function (): string {
      return this.order.id || '';
    },
    customerId: function (): string {
      return this.order.customer?.id || '';
    },
    customerName: function (): string {
      return this.order.customer?.nickname || '';
    },
    customerMobileNo: function (): string {
      return this.order.customer?.mobileNumber || '';
    },
    customerEmail: function (): string {
      return this.order.customer?.email || '';
    },
  },
  methods: {
    getGenderName(order: OrderSheetDetail) {
      let text = '-';
      switch (order.customer?.gender) {
        case 'M':
          text = '남성';
          break;
        case 'F':
          text = '여성';
          break;
      }
      return text;
    },
  },
});
