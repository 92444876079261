


















































import Vue from 'vue';
import { Table, TableColumn } from 'element-ui';
import TransactionLabel from '@/domain/purchase/components/controls/TransactionLabel.vue';
import PriceLabel from '@/components/Labels/PriceLabel.vue';
import { OrderItem } from '../model/OrderItem';
import { Transaction } from '../model/Transaction';

interface OrderItemRow {
  item: OrderItem;
  transaction: Transaction;
  firstRow: boolean;
}

export default Vue.extend({
  name: 'OrderItemInfo',
  components: {
    TransactionLabel,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    PriceLabel,
  },
  props: {
    value: {
      type: Object,
    },
  },
  computed: {
    transactions: function (): Transaction[] {
      return this.value.transactions || [];
    },
    rows: function (): OrderItemRow[] {
      return this.transactions.flatMap(t =>
        t.items.map((i, index) => {
          return {
            item: i,
            transaction: t,
            firstRow: index == 0,
          };
        })
      );
    },
    canCancel: function (): boolean {
      return this.transactions.some(t => t.items.some(i => i.remains > 0));
    },
  },
  methods: {
    onClickCancel() {
      console.log('onClickCancel');
      this.$emit('cancel', true);
    },
    getTableSpan({
      row,
      column,
      rowIndex,
      columnIndex,
    }: {
      row: OrderItemRow;
      column: any;
      rowIndex: number;
      columnIndex: number;
    }): { rowspan: number; colspan: number } {
      if (columnIndex < 1) {
        if (row.firstRow) {
          return {
            rowspan: row.transaction.items.length,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }

      return {
        rowspan: 1,
        colspan: 1,
      };
    },
    getFripInfo(row: OrderItemRow): string {
      const booking = row.item.booking;
      if (booking) {
        if (booking.schedule) {
          return (
            this.$moment(booking.schedule.term.startedAt).format(
              'YYYY-MM-DD HH:mm'
            ) +
            ' ~ ' +
            this.$moment(booking.schedule.term.endedAt).format(
              'YYYY-MM-DD HH:mm'
            )
          );
        }
        if (booking.expiredAt) {
          return (
            '유효기간 만료일: ' +
            this.$moment(booking.expiredAt).format('YYYY-MM-DD')
          );
        }
      }
      return '';
    },
  },
});
