import { render, staticRenderFns } from "./OrderItemInfo.vue?vue&type=template&id=1aaee13d&"
import script from "./OrderItemInfo.vue?vue&type=script&lang=ts&"
export * from "./OrderItemInfo.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports