






















































import Vue from 'vue';
import { Table, TableColumn } from 'element-ui';
import TransactionLabel from '@/domain/purchase/components/controls/TransactionLabel.vue';
import Instant from '@/components/Labels/Instant.vue';
import { Transaction } from '../model/Transaction';
import {
  TransactionState,
  TransactionType,
} from '@/domain/purchase/model/enums';

export default Vue.extend({
  name: 'TransactionInfo',
  components: {
    TransactionLabel,
    Instant,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  props: {
    value: {
      type: Object,
    },
  },
  computed: {
    transactions: function (): Transaction[] {
      return this.value.transactions || [];
    },
  },
  methods: {
    canCancel(row: Transaction): boolean {
      return (
        row.type == TransactionType.CANCELLATION &&
        row.status == TransactionState.REQUESTED
      );
    },
    onClickProcessCancel(row: Transaction): void {
      this.$emit('cancel', row.id);
    },
  },
});
