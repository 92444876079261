




import Vue from 'vue';
import { OrderStateLabels } from '../../model/OrderState';

export default Vue.extend({
  name: 'OrderStatusLabel',
  props: {
    status: {
      type: String,
    },
  },
  computed: {
    label: function (): string {
      return (
        OrderStateLabels.find(i => i.value.toString() == this.status)?.label ||
        ''
      );
    },
  },
});
