var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('content-card',{attrs:{"title":"검색 결과","subtitle":_vm.totalCount.toString()},scopedSlots:_vm._u([{key:"footer",fn:function(){return [(_vm.totalCount > 0)?_c('b-pagination-nav',{staticClass:"mt-3",attrs:{"align":"center","link-gen":_vm.linkGen,"number-of-pages":_vm.numberOfPages,"use-router":""}}):_vm._e()]},proxy:true}])},[(_vm.loading > 0)?_c('spinner'):_c('el-table',{ref:"table",staticClass:"table table-responsive table-flush table-striped align-items-center",attrs:{"empty-text":"검색 결과가 없습니다.","header-row-class-name":"thead-light","header-cell-class-name":"center-table","cell-class-name":"text-center","data":_vm.orders}},[_c('el-table-column',{attrs:{"label":"주문ID","prop":"id"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-link',{attrs:{"to":_vm.$wlink.order(row.id)}},[_vm._v(_vm._s(row.id))])]}}])}),_c('el-table-column',{attrs:{"label":"주문상태"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('order-status-label',{attrs:{"status":row.status.toString()}})]}}])}),_c('el-table-column',{attrs:{"label":"거래정보"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(_vm.getCancelRequestedCount(row) > 0)?_c('h5',{staticClass:"text-warning"},[_vm._v(" 취소 신청("+_vm._s(_vm.getCancelRequestedCount(row))+") ")]):_vm._e(),(_vm.getCancelCompletedCount(row) > 0)?_c('h5',{staticClass:"text-primary"},[_vm._v(" 취소 완료("+_vm._s(_vm.getCancelCompletedCount(row))+") ")]):_vm._e(),(_vm.getCancelFailedCount(row) > 0)?_c('h5',{staticClass:"text-danger"},[_vm._v(" 취소 실패("+_vm._s(_vm.getCancelFailedCount(row))+") ")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"label":"크루명","prop":"customer.nickname"}}),_c('el-table-column',{attrs:{"label":"성별"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getGenderName(row))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"크루 연락처","prop":"customer.mobileNumber"}}),_c('el-table-column',{attrs:{"min-width":200,"label":"상품명","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.getProductInfo(row))}})]}}])}),_c('el-table-column',{attrs:{"label":"구매 수량"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getPurchaseCount(row))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"취소 수량"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getCancelCount(row))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"주문관리"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('frip-button',{attrs:{"outline":"","size":"sm"},on:{"click":function($event){return _vm.onClickDetail(row)}}},[_vm._v("주문관리 ")])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }