import { Term } from '@/common/Term';
import { Dictionary } from 'vue-router/types/router';
import { TransactionActionState } from './TransactionActionState';
import { toStringArray } from '@/common/util/toStringArray';

export interface TransactionFilter {
  ids?: string[] | null;
  productIds?: string[] | null;
  customerIds?: number[] | null;
  nameLike?: string | null;
  emailLike?: string | null;
  phoneLike?: string | null;
  itemIds?: number[] | null;
  transactionTerm?: Term | null;
  actionStatusIn?: TransactionActionState[] | null;
  productTitleLike?: string | null;
  itemNameLike?: string | null;
  orderIds?: string[] | null;
  orderItemIds?: string[] | null;
}

export interface TransactionFilterQuery
  extends Dictionary<string | string[] | undefined> {
  ids?: string[];
  productIds?: string[];
  customerIds?: string[];
  nameLike?: string;
  emailLike?: string;
  phoneLike?: string;
  itemIds?: string[];
  startedAt?: string;
  endedAt?: string;
  actionStatusIn?: string[];
  productTitleLike?: string;
  itemNameLike?: string;
  orderIds?: string[];
  orderItemIds?: string[];
}

export enum TransactionTermSearchType {
  REGISTERED = 'REGISTERED',
  ASKED = 'ASKED',
  STARTED = 'STARTED',
  COMPLETED = 'COMPLETED',
}

/*
주문ID
상품명
상품ID
아이템명
거래 ID
*/
export const toFilter = (query: TransactionFilterQuery): TransactionFilter => {
  let term: Term | null = null;
  if (query.startedAt || query.endedAt) {
    term = {
      startedAt: query.startedAt ? Number(query.startedAt) : null,
      endedAt: query.endedAt ? Number(query.endedAt) : null,
    };
  }
  return {
    //descriptionLike: query.descriptionLike || null,
    ids: toStringArray(query.ids) || null,
    productIds: toStringArray(query.productIds) || null,
    customerIds: query.customerIds?.map(Number) || null,
    nameLike: query.nameLike || null,
    emailLike: query.emailLike || null,
    phoneLike: query.phoneLike || null,
    itemIds: query.itemIds?.map(Number) || null,
    transactionTerm: term as Term,
    actionStatusIn:
      toStringArray(query.actionStatusIn)?.map(
        i => i as TransactionActionState
      ) || null,
    productTitleLike: query.productTitleLike || null,
    itemNameLike: query.itemNameLike || null,
    orderIds: toStringArray(query.orderIds) || null,
    orderItemIds: toStringArray(query.orderItemIds) || null,
  };
};
