







import Vue from 'vue';
import TransactionFilterForm from '../components/TransactionFilterForm.vue';
import TransactionList from '../components/TransactionList.vue';

export default Vue.extend({
  name: 'TransactionListContainer',
  components: { TransactionFilterForm, TransactionList },
  methods: {
    onRefresh() {
      (this.$refs.list as any).refresh();
    },
  },
});
