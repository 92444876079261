






















import Vue from 'vue';
import { ORDER_DETAIL_QUERY } from '../graphql/query';
import OrderInfo from '../components/OrderInfo.vue';
import TransactionInfo from '../components/TransactionInfo.vue';
import OrderItemInfo from '../components/OrderItemInfo.vue';
import OrderPaymentInfo from '../components/OrderPaymentInfo.vue';
import CancelPopup from '../components/CancelPopup.vue';
import { TransactionType } from '@/domain/purchase/model/enums';
import { OrderSheetDetail } from '../model/OrderSheetDetail';

export default Vue.extend({
  name: 'OrderContainer',
  components: {
    OrderInfo,
    TransactionInfo,
    OrderItemInfo,
    OrderPaymentInfo,
    CancelPopup,
  },
  data() {
    return {
      orderId: this.$route.params.id,
      order: {} as OrderSheetDetail,
    };
  },
  apollo: {
    order: {
      query: ORDER_DETAIL_QUERY,
      variables(): { id: string } {
        return {
          id: this.orderId,
        };
      },
      update: data => data.order.order,
    },
  },
  updated() {
    const transaction = this.order.transactions?.find(
      t => t.type == TransactionType.PURCHASE
    );

    const itemCount =
      transaction?.items.reduce(
        (total, originItem) => total + originItem.count,
        0
      ) ?? 1;

    if (this.order.usedCouponCount > 1 && itemCount > 1) {
      this.$modal.show({
        title: '부분 취소 불가',
        message: '주문건은 부분 취소가 불가능 합니다.',
      });
    }
  },
  methods: {
    onCancelRequest() {
      console.log('onCancelRequest');

      const transaction = this.order.transactions?.find(
        t => t.type == TransactionType.PURCHASE
      );

      if (transaction) {
        const popup = this.$refs.cancelPopup as InstanceType<
          typeof CancelPopup
        >;
        popup?.open(this.order.id, transaction, this.order.usedCouponCount);
      }
    },
    onCancelProcess(tid: string) {
      console.log('onCancelProcess');

      const transaction = this.order.transactions?.find(t => t.id == tid);

      if (transaction) {
        const popup = this.$refs.cancelPopup as InstanceType<
          typeof CancelPopup
        >;
        popup?.open(this.order.id, transaction, this.order.usedCouponCount);
      }
    },
    onCancelSuccess() {
      console.log('onCancelSuccess');
      this.$apollo.queries.order.refresh();
    },
  },
});
