var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('content-card',{attrs:{"title":"검색 결과","subtitle":_vm.totalCount.toString()},scopedSlots:_vm._u([{key:"footer",fn:function(){return [(_vm.totalCount > 0)?_c('b-pagination-nav',{staticClass:"mt-3",attrs:{"align":"center","link-gen":_vm.linkGen,"number-of-pages":_vm.numberOfPages,"use-router":""}}):_vm._e()]},proxy:true}])},[(_vm.loading > 0)?_c('spinner'):_c('el-table',{ref:"table",staticClass:"table table-responsive table-flush table-striped align-items-center",attrs:{"empty-text":"검색 결과가 없습니다.","header-row-class-name":"thead-light","header-cell-class-name":"center-table","cell-class-name":"text-center","data":_vm.transactions},on:{"row-click":_vm.onClickDetail}},[_c('el-table-column',{attrs:{"label":"주문ID","prop":"order.id"}}),_c('el-table-column',{attrs:{"label":"거래일"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('instant',{attrs:{"at":row.firmedAt}})]}}])}),_c('el-table-column',{attrs:{"label":"거래 상태"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('transaction-action-status-label',{attrs:{"status":row.actionStatus}})]}}])}),_c('el-table-column',{attrs:{"label":"크루명","prop":"order.customer.nickname"}}),_c('el-table-column',{attrs:{"label":"크루 연락처","prop":"order.customer.mobileNumber"}}),_c('el-table-column',{attrs:{"min-width":200,"label":"상품명","show-overflow-tooltip":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{domProps:{"innerHTML":_vm._s(_vm.getProductInfo(row))}})]}}])}),_c('el-table-column',{attrs:{"label":"수량"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getCount(row))+" ")]}}])}),_c('el-table-column',{attrs:{"label":"거래액"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('money-label',{attrs:{"amount":row.totalAmount}})]}}])}),_c('el-table-column',{attrs:{"label":"결제 내역"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.getPaymentInfo(row))+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }