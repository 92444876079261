



























































































































































































import Vue from 'vue';
import { Table, TableColumn, Popover, Tag } from 'element-ui';
import PriceLabel from '@/components/Labels/PriceLabel.vue';
import MoneyLabel from '@/components/Labels/MoneyLabel.vue';
import BankAccountInput from './BankAccountInput.vue';
import { TransactionType } from '@/domain/purchase/model/enums';
import { Transaction } from '../model/Transaction';
import { ItemInfo } from '../model/ItemInfo';
import { OrderItemBase } from '../model/OrderItemBase';
import { OrderItemUnit } from '../model/OrderItemUnit';
import { CommonState } from '@frientrip/domain';
import { CancelRequestParam } from '../model/CancelRequestParam';
import { CancelReasonKind } from '../model/CancelReasonKind';
import { CancelRequestItemParam } from '../model/CancelRequestItemParam';
import { apolloClient } from '@/apolloClient';
import { OrderService } from '../service/OrderService';
import { parseApiError } from '@/common/ApiErrorParser';
import { EstimatedCancelResult } from '../model/EstimatedCancelResult';
import { CancelInformation } from '../model/CancelInformation';
import { CancelPaymentInfo } from '../model/CancelPaymentInfo';
import { RefundAccount } from '../model/RefundAccount';
import { CancelRequestItemRefundInfo } from '../model/CancelRequestItemRefundInfo';
import { isHostAdmin } from '@/env';

const service = new OrderService(apolloClient);

interface CancelItemUnit extends OrderItemUnit {
  targetOrderItemId: string; // originId
  info: ItemInfo;
  item: OrderItemBase;
  refundRatio: number;
  firstRow: boolean;
  rowCount: number;
}

export default Vue.extend({
  name: 'CancelPopup',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Popover.name]: Popover,
    [Tag.name]: Tag,
    PriceLabel,
    MoneyLabel,
    BankAccountInput,
  },
  data() {
    return {
      opened: false,
      orderId: '',
      transaction: {} as Transaction,
      units: [] as CancelItemUnit[],
      selection: [] as CancelItemUnit[],
      forced: false,
      manualRefund: false,
      usedBankAccount: false,
      refundByPoint: false,
      refundBank: {
        code: '',
        accountNo: '',
        holder: '',
        verified: false,
      } as RefundAccount,
      estimatedResult: null as EstimatedCancelResult | null,
      usedCouponCount: 0,
    };
  },
  computed: {
    requested: function (): boolean {
      return this.transaction?.type == TransactionType.CANCELLATION;
    },
    title: function (): string {
      return this.requested ? '취소 신청 처리' : '취소 처리';
    },
    titleOfItem: function (): string {
      return this.requested ? '취소 신청된 아이템' : '취소 가능한 아이템';
    },
    cancelInfos: function (): CancelInformation[] {
      return this.estimatedResult?.infos || [];
    },
    cancelPayments: function (): CancelPaymentInfo[] {
      return this.estimatedResult?.payments || [];
    },
  },
  methods: {
    clear() {
      this.orderId = '';
      this.transaction = {} as Transaction;
      this.units = [] as CancelItemUnit[];
      this.selection = [] as CancelItemUnit[];
      this.forced = false;
      this.manualRefund = false;
      this.usedBankAccount = false;
      this.refundByPoint = false;
      this.refundBank = {
        code: '',
        accountNo: '',
        holder: '',
        verified: false,
      } as RefundAccount;
      this.estimatedResult = null;
    },
    open(
      orderId: string,
      transaction: Transaction,
      couponUsedCount: number
    ): void {
      console.log('open start?');
      console.log(`couponUsedCoupont : ${couponUsedCount}`);
      this.clear();

      this.usedCouponCount = couponUsedCount;
      this.orderId = orderId;
      this.transaction = transaction;
      this.units = this.buildCancelItemUnits(transaction);
      this.opened = true;
    },
    getTableSpan({
      row,
      column,
      rowIndex,
      columnIndex,
    }: {
      row: CancelItemUnit;
      column: any;
      rowIndex: number;
      columnIndex: number;
    }): { rowspan: number; colspan: number } {
      const colCount = this.manualRefund ? 6 : 5;
      if (columnIndex < colCount) {
        if (row.firstRow) {
          return {
            rowspan: row.rowCount,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
      return {
        rowspan: 1,
        colspan: 1,
      };
    },
    handleSelectionChange(items: CancelItemUnit[]): void {
      console.log('handleSelectionChange:', items);
      this.selection = items;
    },
    buildCancelItemUnits(transaction: Transaction): CancelItemUnit[] {
      if (transaction?.type == TransactionType.CANCELLATION) {
        return transaction.items.flatMap(i =>
          i.units.map((u, index) => {
            return {
              ...u,
              targetOrderItemId: i.origin!.id,
              info: i.info,
              item: i.origin!,
              refundRatio: 0,
              firstRow: index == 0,
              rowCount: i.units.length,
            };
          })
        );
      }

      return transaction.items.flatMap(i =>
        i.units
          .filter(u => u.status == CommonState.ACTIVE)
          .map((u, index) => {
            return {
              ...u,
              targetOrderItemId: i.id,
              info: i.info,
              item: i,
              refundRatio: 0,
              firstRow: index == 0,
              rowCount: i.remains,
            };
          })
      );
    },
    buildCancelRequestParma(): CancelRequestParam {
      return {
        kind: isHostAdmin() ? CancelReasonKind.HOST : CancelReasonKind.SYSTEM, // TODO: 입력받도록 수정 해야 함.
        fullyCancel: false,
        forced: this.forced,
        manualRefund: this.manualRefund,
        refundByPoint: this.refundByPoint,
        orderId: this.orderId,
        reason: '',
        refundAccount: this.usedBankAccount ? this.refundBank : null,
        refunds: this.manualRefund ? this.buildCancelFeeInfo() : [],
        items: this.requested
          ? null
          : this.buildCancelRequestItemParams(this.selection),
      };
    },
    buildCancelRequestItemParams(
      items: CancelItemUnit[]
    ): CancelRequestItemParam[] {
      const itemTotalCount = this.calculateQuantity();

      if (items.length == 0) {
        throw new Error('취소할 아이템을 선택 해주세요.');
      }

      if (this.usedCouponCount > 1 && itemTotalCount !== items.length) {
        throw new Error('중복 쿠폰 사용은 부분 취소가 불가능 합니다.');
      }

      const params: CancelRequestItemParam[] = [];

      for (const item of items) {
        let param = params.find(p => p.orderItemId == item.targetOrderItemId);
        if (!param) {
          param = {
            orderItemId: item.targetOrderItemId,
            indices: [],
            quantity: 0,
          };
          params.push(param);
        }
        param.indices.push(item.indexNo);
        param.quantity--;
      }
      return params;
    },
    buildCancelFeeInfo(): CancelRequestItemRefundInfo[] {
      const list: CancelRequestItemRefundInfo[] = [];
      for (let item of this.units.filter(i => i.firstRow)) {
        if (item.refundRatio > 100 || item.refundRatio < 0) {
          throw new Error('취소 수수료는 0~100 값만 허용됩니다.');
        }

        list.push({
          targetOrderItemId: item.targetOrderItemId,
          ratio: item.refundRatio,
        });
      }
      return list;
    },
    async onClickEstimateCancel() {
      console.log('onClickEstimateCancel');
      try {
        const param = this.buildCancelRequestParma();
        const result = await service.estimate(
          param,
          this.requested ? this.transaction.id : undefined
        );
        console.log(result);
        this.estimatedResult = result;

        if (!result.success) {
          let refundText = '';

          if (result.refunds?.length > 0) {
            refundText += result.refunds[0].text;
          }

          this.$modal.show({
            title: '취소 계산 실패',
            html: `${result.message}<br/><br/>${refundText}`,
            type: 'warning',
          });
        }
      } catch (err: any) {
        console.error(err);
        const { message } = parseApiError(err);
        this.$modal.show({
          title: '취소 계산 실패',
          message: message,
          type: 'warning',
        });
      }
    },
    async onClickCancelModal() {
      for (const item of this.selection) {
        if (
          await service.checkExistenceOfInvoiceByOrderItemUnitInfo(
            item.targetOrderItemId,
            item.indexNo
          )
        ) {
          return this.$modal.show(
            {
              title: '주문 취소 신청 주의',
              message: '이미 지급서가 생성된 결제 건입니다. 계속 하시겠습니까?',
              type: 'warning',
              showCancelButton: true,
              cancelText: '취소',
            },
            () => {
              this.onClickCancel();
            }
          );
        }
      }
      this.onClickCancel();
    },
    async onClickCancel() {
      console.log('onClickCancel');
      try {
        const param = this.buildCancelRequestParma();

        const result = this.requested
          ? await service.process(this.transaction.id, param)
          : await service.cencel(param);

        console.log('result:', result);

        if (result.success) {
          this.$modal.show(
            {
              title: '취소 처리 성공',
              message: result.message,
              type: 'primary',
            },
            () => {
              this.opened = false;
            }
          );
          this.$emit('success', true);
        } else {
          this.$modal.show({
            title: '취소 처리 실패',
            message: result.message,
            type: 'danger',
          });
        }
      } catch (err: any) {
        console.error(err);
        const { message } = parseApiError(err);
        this.$modal.show({
          title: '취소 처리 실패',
          message: message,
          type: 'warning',
        });
      }
    },
    calculateQuantity(): number {
      return this.transaction.items.reduce(
        (total, originItem) => total + originItem.count,
        0
      );
    },
  },
});
