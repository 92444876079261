var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('content-card',{attrs:{"title":"결제 내역"}},[_c('el-table',{ref:"table",staticClass:"table table-responsive table-flush table-striped align-items-center",attrs:{"empty-text":"검색 결과가 없습니다.","header-row-class-name":"thead-light","header-cell-class-name":"center-table","cell-class-name":"text-center","data":_vm.rows,"span-method":_vm.getTableSpan}},[_c('el-table-column',{attrs:{"label":"주문번호(PG)"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('div',[_c('span',{ref:"{{ row.payment.id }}",on:{"click":function($event){return _vm.copyPid(row.payment.id)}}},[_vm._v(_vm._s(row.payment.id)+" ")])]),_c('div',{ref:"copyRef"})]}}])}),_c('el-table-column',{attrs:{"label":"거래유형"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('transaction-label',{attrs:{"transaction":row.transaction}})]}}])}),_c('el-table-column',{attrs:{"label":"결제수단"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.payment.method == 'COUPON')?_c('coupon-payment-link',{staticClass:"h5",attrs:{"pid":row.payment.id}}):_c('span',{staticClass:"h5"},[_vm._v(_vm._s(row.payment.methodLabel))])]}}])}),_c('el-table-column',{attrs:{"label":"금액"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('money-label',{attrs:{"amount":row.payment.amount}})]}}])}),_c('el-table-column',{attrs:{"label":"결제 상태"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(row.payment.status)+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }