







import Vue from 'vue';
import OrderSearchFilterForm from '../components/OrderSearchFilterForm.vue';
import OrderSearchList from '../components/OrderSearchList.vue';

export default Vue.extend({
  name: 'OrderListContainer',
  components: { OrderSearchFilterForm, OrderSearchList },
  methods: {
    onRefresh() {
      (this.$refs.list as any).refresh();
    },
  },
});
