




import Vue from 'vue';
import { TransactionActionStateLabels } from '../../model/TransactionActionState';

export default Vue.extend({
  name: 'TransactionActionStatusLabel',
  props: {
    status: {
      type: String,
    },
  },
  computed: {
    label: function (): string {
      return (
        TransactionActionStateLabels.find(
          i => i.value.toString() == this.status
        )?.label || ''
      );
    },
    variant: function (): string {
      return (
        TransactionActionStateLabels.find(
          i => i.value.toString() == this.status
        )?.variant || 'secondary'
      );
    },
  },
});
